import { backendClient, commonClient } from "./clients";

async function isAuthorized(user) {
  try {
    const response = await backendClient.request({
      url: "/GetAuthorizations",
      method: "get",
    });
    return response.data;
  } catch (error) {
    throw error; 
  }
}

async function addAccess(company, user) {
  return new Promise((resolve, reject) => {
    if (!company || !user) reject();

    backendClient
      .request({
        url: "/AccessCompany",
        method: "post",
        data: {
          company,
          user,
        },
      })
      .then((res) => {
        if (res.data.status === "error") throw new Error(res.data.error);
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

async function addAuthenticator(user) {
  return new Promise((resolve, reject) => {
    if (!user) reject();

    backendClient
      .request({
        url: "/AddUserAuthenticator",
        method: "post",
        data: {
          user,
        },
      })
      .then((res) => {
        if (res.data.status === "error") throw new Error(res.data.error);
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

async function removeAuthenticator(user) {
  return new Promise((resolve, reject) => {
    if (!user) reject();

    backendClient
      .request({
        url: "/RemoveUserAuthenticator",
        method: "post",
        data: {
          user,
        },
      })
      .then((res) => {
        if (res.data.status === "error") throw new Error(res.data.error);
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

async function removeAccess(company, user) {
  return new Promise((resolve, reject) => {
    if (!company || !user) reject();

    backendClient
      .request({
        url: "/RemoveAccess",
        method: "post",
        data: {
          company,
          user,
        },
      })
      .then((res) => {
        if (res.data.status === "error") throw new Error(res.data.error);
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

async function authenticate(company) {
  const url = commonClient.getUri({
    url: "/AuthorizeCompany",
    method: "get",
    params: {
      access: company,
    },
  });
  window.open(url, "_self");
}

async function getAnafTokenCopy(company) {
  try {
    const response = await backendClient.request({
      url: "/GetAnafTokenCopy",
      method: "post",
      data: {
        company,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function authorize(code, id) {
  return new Promise((resolve, reject) => {
    if (!code || !id) reject();

    commonClient
      .request({
        url: "/Authorize",
        method: "get",
        params: {
          state: id,
          auth_code: code,
        },
      })
      .then((res) => {
        if (res.data.status === "error") throw new Error(res.data.error);
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

async function refresh(id) {
  if (!id) throw new Error("ID undefined");

  return backendClient
    .request({
      url: "/Refresh",
      method: "post",
      data: {
        authId: id,
      },
    })
    .then((res) => {
      console.debug("Refresh successful");
    })
    .catch((err) => {
      console.debug("Refresh failed");
      // throw new Error("Failed to refresh");
    });
}

async function revoke(id) {
  return backendClient
    .request({
      url: "/Revoke",
      method: "get",
      params: {
        id,
      },
    })
    .then((res) => {
      if (res.data.status === "error") throw new Error(res.data.error);
      return res.data;
    });
}

async function login(username, password, code = null) {
  return commonClient
    .request({
      url: "/Login",
      method: "post",
      data: {
        username,
        password,
        code,
      },
    })
    .then((res) => {
      return res.data;
    });
}
async function register(user) {
  return commonClient
    .request({
      url: "/Register",
      method: "post",
      data: {
        user,
      },
    })
    .then((res) => {
      return res.data;
    });
}

async function checkAvailable(email) {
  return commonClient
    .request({
      url: "/AvailableAccount",
      method: "post",
      data: {
        email,
      },
    })
    .then((res) => {
      return res.data;
    });
}

async function confirmAccount(hash, code) {
  return commonClient
    .request({
      url: "/ActivateAccount",
      method: "post",
      data: {
        hash,
        code,
      },
    })
    .then((res) => {
      return res.data;
    });
}

async function updateCompanyData(user, company) {
  return backendClient
    .request({
      url: "/UpdateUserCompany",
      method: "post",
      data: {
        user,
        company,
      },
    })
    .then((res) => {
      return res.data;
    });
}

async function ChangePassword(user, oldPass, newPass) {
  return backendClient
    .request({
      url: "/ChangePassword",
      method: "post",
      data: {
        user,
        oldPass,
        newPass,
      },
    })
    .then((res) => {
      return res.data;
    });
}

async function ForgotPassword(email) {
  return commonClient
    .request({
      url: "/ForgotPassword",
      method: "post",
      data: {
        email,
      },
    })
    .then((res) => {
      return res.data;
    });
}

async function ChangePasswordForgot(hash, newPass) {
  return commonClient
    .request({
      url: "/ChangePasswordForgot",
      method: "post",
      data: {
        hash,
        newPass,
      },
    })
    .then((res) => {
      return res.data;
    });
}
async function RemoveAccountAndAllData(user, company) {
  return new Promise((resolve, reject) => {
    backendClient
      .request({
        url: "/RemoveAccountData",
        method: "post",
        data: {
          company,
          user,
        },
      })
      .then((res) => {
        if (res.data.status === "error") throw new Error(res.data.error);
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

async function createPaymentRedirect(
  userId,
  subscriptionName,
  subscriptionID,
  plataRec,
  until,
  isOPPayment,
  paymentDetails
) {
  return backendClient.request({
    url: "/AuthorizeNewPayment",
    method: "post",
    data: {
      userId: userId,
      subscriptionName: subscriptionName,
      subscriptionID: subscriptionID,
      service: "efactura",
      plataRec: plataRec,
      untilRec: until,
      isOPPayment: isOPPayment,
      paymentDetails: paymentDetails,
    },
  });
}

async function checkPaymentStatus(orderId) {
  return backendClient.request({
    url: "/PaymentCheckStatusRequest",
    method: "post",
    data: { orderId },
  });
}

async function createBackup(service) {
  return backendClient
    .request({
      url: "/AuthorizeBackup",
      method: "post",
      data: {
        service
      }
    })
    .then((res) => res.data.url)
    .then((url) => window.open(url, "_self"));
}

async function checkConectionResult(code, service) {
  return backendClient.request({
    url: "/SetBackup",
    method: "post",
    data: { 
      code, 
      service 
    },
  });
}

async function getBackupStatus() {
  return backendClient
    .request({
      url: "/GetUserBackup",
      method: "post",
    })
    .then((res) => res.data);
}

async function syncronizeBackup(service) {
  return backendClient
    .request({
      url: "/SyncronizeBackup",
      method: "post",
      data: {
        service
      },
      timeout: 0,
    })
    .then((res) => res.data);
}

async function setBackupFormat(format) {
  return backendClient.request({
    url: "/SetBackupFormat",
    method: "post",
    data: { 
      format
    },
  });
}

async function getBackupFormat() {
  return backendClient.request({
    url: "/GetBackupFormat",
    method: "post",
  });
}

async function updateUserData(userId, updateUser) {
  return backendClient.request({
    url: "/UpdateUserData",
    method: "post",
    data: {
      userId: userId,
      updateUser: updateUser,
    },
  });
}

export {
  isAuthorized,
  addAccess,
  removeAccess,
  updateCompanyData,
  authenticate,
  authorize,
  refresh,
  revoke,
  login,
  register,
  checkAvailable,
  confirmAccount,
  ChangePassword,
  ForgotPassword,
  ChangePasswordForgot,
  addAuthenticator,
  removeAuthenticator,
  RemoveAccountAndAllData,
  createPaymentRedirect,
  checkPaymentStatus,
  createBackup,
  checkConectionResult,
  getBackupStatus,
  syncronizeBackup,
  setBackupFormat,
  getBackupFormat,
  updateUserData,
  getAnafTokenCopy,
};
