import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    isSmartphone: false,       // Inițializare pentru tipul de dispozitiv
};
export const setDeviceSlice = createSlice({
    name: "deviceSlice",
    initialState,
    reducers: {

        setDeviceSelection: (state, action) => {
            state.isSmartphone = action.payload;
        },
    },
});

export const { setDeviceSelection } = setDeviceSlice.actions;

export const isSmartphone = (state) => state.deviceReducer.isSmartphone;

export default setDeviceSlice.reducer;

