import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CheckUserSubscriptionStatus } from "services/companyAPI";
import { getBackupStatus, isAuthorized, login } from "services/authAPI";

import { setCompanies } from "./companySlice";
import { store } from "./store";
import { setSubscriptionStatus } from "./subscriptionStatusSlice";

const initialState = {
  user: null,
};

// export const signinAsync = createAsyncThunk(
// 	'user/signin',
// 	(user, thunk) => login(user.username, user.password, user.code)
// 		.then(res => {message
// 			return res?.user;
// 		})
// 		.then(user => {
// 			return user;
// 		})
// 		.catch(err => {
// 			// console.debug('Failed login');
// 			return thunk.rejectWithValue(err.response.data);
// 		})
// );
export const signinAsync = createAsyncThunk(
  "user/signin",
  async (user, thunkAPI) => {
    try {
      return await login(user.username, user.password, user.code)
        .then((res) => res.user)
        .catch((error) => {
          if (!!error?.response?.data?.authenticator)
            return thunkAPI.rejectWithValue(error.response.data);

          let userFriendlyMessage;
          if (error.response) {
            switch (error.response.status) {
              case 400:
                userFriendlyMessage =
                  "Request invalid. Verificați datele introduse.";
                break;
              case 401:
                userFriendlyMessage = error.response.data.error;
                break;
              case 500:
                userFriendlyMessage = "Eroare de server. Încercați mai târziu.";
                break;
              default:
                userFriendlyMessage = "Eroare necunoscută. Încercați din nou.";
            }
            throw new Error(userFriendlyMessage);
          } else {
            throw new Error(
              "Ne pare rău, nu s-a putut stabili conexiunea cu serverul."
            );
          }
        });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message || "Network Error",
      });
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPlan: (state, action) => {
      state.user.plan = action.payload;
    },
    setNotificationStatus: (state, action) => {
      state.user.notificationStatus = action.payload;
    },
    setUserCompany: (state, action) => {
      state.user.company = action.payload;
    },
    setUserLegalEntity: (state, action) => {
      state.user.legalEntity = action.payload;
    },
    setUpdateCnp: (state, action) => {
      state.user.cnp = action.payload;
    },
    setBackup: (state, action) => {
      state.user.backup = action.payload;
    },
    signout: (state) => {
      state.user = null;
    },
    setAuthenticator: (state, action) => {
      state.user.hasAuthenticator = action.payload;
    },
    // setHasRequestForPayment: (state, action) => {
    //   state.user.hasRequestForPayment = action.payload;
    // },
    signin: (state, action) => {
      let user = action.payload;
      state.user = user;
    },
    updateUserisDeletionAllowed: (state, action) => {
      state.user.isDeletionAllowed = action.payload;
      // state.user = null;
    },
    updateLastName: (state, action) => {
      state.user.lastName = action.payload;
    },
    updateFirstName: (state, action) => {
      state.user.firstName = action.payload;
    },
    updateTelephone: (state, action) => {
      state.user.telephone = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signinAsync.pending, (state) => { })
      .addCase(signinAsync.fulfilled, (state, action) => {
        let user = action.payload;
        state.user = user;
      })
      .addCase(signinAsync.rejected, (state) => {
        state.user = null;
      });
  },
});

export const unloadUser = () => {
  // console.trace("Called unset user");
};

export const loadUser = (user) => {
  // console.trace("Called set user");
  // if (!user?.token)
  //   return;

  let [, data] = user.token.split(".");
  let tokenData = JSON.parse(window.atob(data));

  // console.log("asdasdas", tokenData);

  isAuthorized(user.email)
    .then((res) => {
      let authorizations = res?.authorizations?.map(({ company, ...auth }) => ({
        ...company,
        ...auth,
        id: company.id,
        authorization: auth.id,
        keys: tokenData.subscriptionKeys,
        backup: tokenData.backup,
      }));
      store.dispatch(setCompanies(authorizations));

      // authorizations.forEach(company => {
      // 	let id = `missing_token_${company.cif}`;
      // 	if (!!company.isAuthorized)
      // 		store.dispatch(removeAlert(id));
      // 	else
      // 		store.dispatch(addPersistentAlert({
      // 			type: 'error',
      // 			message: "Status companie",
      // 			description: `Nu sunteti autorizat pentru ${company.name}`,
      // 			id
      // 		}));
      // });
    })
    .catch((err) => {
      // console.error("Problem retrieving companies: ", err);
      store.dispatch(setCompanies([]));
    });

  getBackupStatus()
    .then((res) => {
      store.dispatch(setBackup(res.backup));
    })
    .catch((err) => {
      console.error("Problem retrieving backup: ", err);
      store.dispatch(setBackup(null));
    });

  CheckUserSubscriptionStatus()
    .then((res) => {
      // console.log("datele din verificare subscriptie sunt:", res)
      store.dispatch(setSubscriptionStatus(res));
    })
    .catch((err) => {
      // console.log("eroare verificare subscriptie:", err);
      store.dispatch(setSubscriptionStatus(null));
    });
};

export const selectUser = (state) => {
  return state.userReducer.user;
};

export const hasAuthenticator = (state) => {
  return !!state.userReducer.user?.hasAuthenticator;
};

export const {
  signin,
  signout,
  setPlan,
  setNotificationStatus,
  setUserLegalEntity,
  setUserCompany,
  setUpdateCnp,
  setBackup,
  setAuthenticator,
  // setHasRequestForPayment,
  updateUserisDeletionAllowed,
  updateLastName,
  updateFirstName,
  updateTelephone,
} = userSlice.actions;

export default userSlice.reducer;
